import React, { Component } from 'react';
import './assets/css/reset.css';
import './assets/css/fonts.css';
// import Checkout from './pages/orders/Checkout';
import Layout from './layout/Layout';
import AuthLayout from './layout/AuthLayout';
import CompleteRegLayout from './layout/CompleteRegLayout';
import { Switch, Route } from 'react-router-dom';

import { connect } from 'react-redux';
import appConst from './config/const';
import _ from 'lodash';
import baseHelper from './helpers/BaseHelper';
import queryString from 'query-string';

import * as types from './redux/futures/auth/types';
import IframeLayout from './layout/IframeLayout';

class App extends Component {
  constructor(props) {
    super(props);
    const token = baseHelper.getCookie(appConst.LADI_UID_TOKEN);
    this.state = {

      search: props.location.search,
      path: props.location.pathname,
      isAuth: token
    }

  }
  // componentDidMount (){
  // const token = baseHelper.getCookie(appConst.LADI_UID_TOKEN);
  // if (token) {

  // this.getUserinfo({
  //   token,
  //   search: this.state.search,
  //   path: this.state.path
  // });
  // } 
  // this.setState({
  //   isAuth: token
  // });

  // }

  // componentWillMount() {
  // this.redirectAuth(this.props);
  // }

  redirectAuth(props) {
    const token = baseHelper.getCookie(appConst.LADI_UID_TOKEN);
    if (!token) {
      if (!_.includes(['/signin', '/signup', '/forgottenpass', '/resetpass', '/oauth2-facebook', '/oauth2-google', '/cookie', '/checkout', '/verify-email'], props.location.pathname)) {
        props.history.push('/signin');
      }
      return;
    }
  }

  // getUserinfo = (data) => {
  //   this.props.getUserinfo(data);
  // }

  // componentWillReceiveProps(nextProps) { 

  //   if (nextProps.redirectTo) {
  //     this.props.history.push(nextProps.redirectTo);
  //   }

  // this.redirectAuth(nextProps);
  // }

  getLayout = () => {

    if (this.props.location.pathname == '/iframe') {
      return <IframeLayout />;
    }
    let token = baseHelper.getCookie(appConst.LADI_UID_TOKEN);
    let userinfo = baseHelper.getCookie(appConst.USER_INFO);

    const parsed = queryString.parse(this.props.location.search);
    if (parsed.logout) {

      baseHelper.removeCookie(appConst.USER_INFO);
      baseHelper.removeCookie(appConst.LADI_UID_TOKEN);
      token = false;
      userinfo = false;
    }

    if (!token || !userinfo) {

      baseHelper.removeCookie(appConst.LADI_UID_TOKEN, null);
      baseHelper.removeCookie(appConst.USER_INFO, null);
      if (!_.includes(['/signin', '/signup', '/forgottenpass', '/resetpass', '/oauth2-facebook', '/oauth2-google', '/cookie', '/checkout', '/verify-email'], this.props.location.pathname)) {

        this.props.history.push('/signin');
      }
      return <Route path='/(checkout|signin|signup|forgottenpass|resetpass|oauth2-facebook|oauth2-google|cookie|verify-email)' render={({ location }) => (
        <AuthLayout />
      )} />

    }

    if (!userinfo.phone) {
      if (!_.includes(['/complete-reg'], this.props.location.pathname)) {

        this.props.history.push('/complete-reg');
      }
      return <CompleteRegLayout />
    }



    return <Layout />;
  }

  render() {

    return (
      <Switch>
        {this.getLayout()}
      </Switch>
    )
  }

}


const mapDispatchToProps = (dispatch) => {
  return {
    // getUserinfo: (data) => dispatch(authActions.getUserInfo(data))
  }
}

const mapStateToProps = (state) => ({
  // ...state.auth 
});


export default connect(mapStateToProps, mapDispatchToProps)(App);
