import React from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import authActions from '../../redux/futures/auth/actions';
import * as types from '../../redux/futures/auth/types';
import _ from 'lodash';
import Input from '../../components/Input';
import i18n from '../../i18n';
import queryString from 'query-string';
import appConst from '../../config/const';
import baseHelper from '../../helpers/BaseHelper';
import LadiIframe from './LadiIframe';
import PoweredBy from '../../components/PoweredBy';
import LadiAppLogo from '../../components/LadiAppLogo';
import LadiLanguage from '../../components/LadiLanguage';
class CompleteRegister extends React.Component {

    constructor(props) {
        super(props);
        const parsed = queryString.parse(this.props.location.search);
        this.state = {
            email: '',
            password: '',
            app_code: '',
            phone: '',
            fullname: '',
            refObject: parsed,
            loading: false,
            typeInput: 'password',
            iconClass: 'icon-preview',
            classNormal: "ladiui btn btn-primary full-width",
            classLoading: "ladiui loader btn btn-primary full-width",
            classApply: "ladiui btn btn-primary full-width",
            search: this.props.location.search
        }
        this.inputsRef = new Set();
        this.ref = React.createRef();
    }

    componentDidMount() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.action == types.COMPLETE_REGISTER) {
            this.setState({
                loading: false,
                classApply: this.state.classNormal
            });
            if (nextProps.status) {
                localStorage.setItem('email', this.state.email);
                let parsed = {};
                if (this.state.search) {
                    parsed = queryString.parse(this.props.location.search);
                }
                if (!parsed.callback_url) {
                    // this.props.history.push('/account/profile');
                }
            } else {
                window.LadiUI.toast('danger', '', nextProps.message, 5000, 'bottom-left');
            }
        }

    }

    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    signOut = () => {
        this.props.signOut();
    }
    completeReg = (event) => {
        event.preventDefault();
        // Validate
        let errors = [];
        const inputsRef = _.compact(Array.from(this.inputsRef));
        _.map(inputsRef, ref => {

            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.toast('danger', '', errorMessage);
            return;
        }

        const { password, fullname, phone } = this.state;

        const parsed = queryString.parse(this.props.location.search);

        const data = {
            "password": password,
            "phone": phone,
            fullname
        }
        if (parsed.app_code) {
            data.app_code = parsed.app_code;
        }
        data.search = this.state.search;
        this.props.complete(data);
    }

    showPassword = () => {
        const { typeInput } = this.state;
        if (typeInput == 'password') {
            this.setState({
                typeInput: 'text',
                iconClass: 'icon-b-preview'
            });
        }
        if (typeInput == 'text') {
            this.setState({
                typeInput: 'password',
                iconClass: 'icon-preview'
            });

        }
    }

    render() {
        const { email, password, fullname, phone, typeInput, iconClass } = this.state;
        let userinfo = baseHelper.getCookie(appConst.USER_INFO);
        return (
            appConst.ALLOW_RESISTER &&
            <div className="ladiui auth">
                <div className="ladiui-login">
                    <LadiAppLogo />
                    <h1 className="form-title" style={{ textAlign: "center" }}>{i18n.t('AUTH._COMPLETE_REGISTRATION')}</h1>
                    {/* <div className="language">
                        <LadiLanguage />
                    </div> */}
                    <div className="ladiui-login-form">
                        <div className="ladiui-form create-user-form">
                            <form onSubmit={this.signUp}>
                                <div className="ladiui-form-group">
                                    <label className="ladiui title-text-form-lever2">{i18n.t('AUTH.FULLNAME')}</label>
                                    <Input
                                        ref={(ref) => this.inputsRef.add(ref)}
                                        name="fullname"
                                        validationName="Fullname"
                                        placeholder={i18n.t('AUTH.FILL_YOUR_FULLNAME')}
                                        value={fullname}
                                        onChange={this.onChangeInput}
                                        validations={{ isRequired: true }}

                                    />

                                </div>
                                <div className="ladiui-form-group">
                                    <label className="ladiui title-text-form-lever2">{i18n.t('AUTH.EMAIL')}</label>
                                    <input name="totalPrice" className="ladiui form-control" disabled="disable" value={userinfo.email} />
                                </div>
                                <div className="ladiui-form-group">
                                    <label className="ladiui title-text-form-lever2">{i18n.t('AUTH.PASSWORD')}</label>
                                    <Input
                                        ref={(ref) => this.inputsRef.add(ref)}
                                        name="password"
                                        validationName="Password"
                                        placeholder={i18n.t('AUTH.FILL_YOUR_PASS')}
                                        value={password}
                                        onChange={this.onChangeInput}
                                        validations={{ isRequired: true, minLength: 6, maxLength: 20 }}
                                        type={typeInput}
                                    />
                                    <i className={`ladi-icon ` + iconClass + ` ladi-date-picker-icon`} onClick={this.showPassword}></i>
                                </div>

                                <div className="ladiui-form-group">
                                    <label className="ladiui title-text-form-lever2">{i18n.t('AUTH.PHONE')}</label>
                                    <Input
                                        ref={(ref) => this.inputsRef.add(ref)}
                                        name="phone"
                                        validationName="Phone"
                                        placeholder={i18n.t('AUTH.FILL_YOUR_PHONE')}
                                        value={phone}
                                        onChange={this.onChangeInput}
                                        validations={{ isRequired: true, phone: true }}

                                    />
                                </div>
                                <div className="ladiui-form-group">
                                    <button
                                        ref={this.ref}
                                        className={this.state.classApply}
                                        onClick={this.completeReg}
                                        disabled={this.state.loading}
                                    >
                                        {i18n.t('AUTH._COMPLETE_REGISTRATION')}</button>

                                </div>
                                <div className="social-login" style={{ marginTop: '30px' }}>
                                    <a href="#" className="ladiui link" onClick={this.signOut}>{i18n.t('AUTH.SIGN_IN_OTHER_ACC')}</a>
                                </div>
                            </form>
                        </div>
                    </div>
                    <PoweredBy />
                </div>
                <LadiIframe />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        complete: (data) => dispatch(authActions.completeRegister(data)),
        signOut: () => dispatch(authActions.signOut())

    }
}

const mapStateToProps = (state) => ({ ...state.auth });

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompleteRegister));