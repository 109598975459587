import React from "react";
import PropTypes from "prop-types";
import authActions from "../../redux/futures/auth/actions";
import _ from "lodash";
import { connect } from "react-redux";
import baseHelper from "../../helpers/BaseHelper";
import appConst from "../../config/const";

import Input from "../../components/Input";
import { NavLink } from "react-router-dom";

import * as types from "../../redux/futures/auth/types";
import i18n from "../../i18n";
import LadiIframe from "./LadiIframe";
import PoweredBy from "../../components/PoweredBy";
import LadiLanguage from "../../components/LadiLanguage";
import LadiAppLogo from "../../components/LadiAppLogo";
class ForgottenPassword extends React.Component {
  static propTypes = {
    email: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      loading: false,
      classNormal: "ladiui btn btn-primary full-width",
      classLoading: "ladiui loader btn btn-primary full-width",
      classApply: "ladiui btn btn-primary full-width",
    };
    this.inputsRef = new Set();
    this.ref = React.createRef();
  }

  componentWillMount() {
    this.redirectAuth(this.props);
  }
  redirectAuth(props) {
    const token = baseHelper.getCookie(appConst.LADI_UID_TOKEN);
    if (token) {
      props.history.push("/dashboard");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.action == types.FORGOTTEN_PASSWORD) {
      this.setState({
        loading: false,
        classApply: this.state.classNormal,
      });
      if (nextProps.status) {
        window.LadiUI.showErrorMessage(i18n.t('BASE.NOTIFICATION'), nextProps.message, 'OK');
      } else {
        window.LadiUI.showErrorMessage(i18n.t('BASE.NOTIFICATION'), nextProps.message, 'OK');
      }
    }
  }

  componentDidUpdate() {
    window.LadiUI.init();
  }

  submit = (event) => {
    event.preventDefault();

    let errors = [];
    const inputsRef = _.compact(Array.from(this.inputsRef));
    _.map(inputsRef, (ref) => {
      if (ref.validate) {
        errors = errors.concat(ref.validate());
      }
    });

    _.compact(errors);
    if (errors.length > 0) {
      const errorMessage = errors.join("<br/>");
      window.LadiUI.toast("error", "", errorMessage);
      return;
    }

    const { email } = this.state;
    const jsonData = {
      email: email,
    };
    this.props.forgottenPassword(jsonData);
    this.state.loading = true;
    this.state.classApply = this.state.classLoading;
  };

  onChangeInput = (event) => {
    this.setState({
      email: event.target.value,
    });
  };

  render() {
    const { email } = this.state;
    // console.log(email);
    const search = this.props.location.search;
    return (
      <div className="ladiui auth">
        <div className="ladiui-login-wrap">
          <div className="ladiui-login">
            <LadiAppLogo />
            <div style={{ textAlign: "center" }}>
              <h1 className="form-title">{i18n.t('AUTH.FORGOT_PASSWORD')}</h1>
              <h4>{i18n.t("FOGGOT_PASSWORD.TITLE")}</h4>
            </div>

            <div className="ladiui-login-form">
              <div className="ladiui-form">
                <form onSubmit={this.submit}>
                  <div className="ladiui form-group" style={{ marginBottom: "16px" }}>
                    <Input
                      ref={(ref) => this.inputsRef.add(ref)}
                      name="email"
                      validationName="Email"
                      placeholder={i18n.t('AUTH.FILL_YOUR_EMAIL')}
                      value={email}
                      onChange={this.onChangeInput}
                      validations={{ isRequired: true, email: true }}
                    />
                  </div>
                  <div className="ladiui-form-group">
                    <button
                      ref={this.ref}
                      className={this.state.classApply}
                      onClick={this.submit}
                      disabled={this.state.loading}
                    >
                      <i
                        style={{
                          display: this.state.loading ? "none" : "",
                          paddingRight: "5px",
                        }}
                      ></i>
                      {i18n.t("AUTH.FOGGOT_PASSWORD_SEND_EMAIL")}
                    </button>
                  </div>
                </form>
              </div>
              <div className="question-form">
                <NavLink
                  to={{
                    pathname: "/signin",
                    search: search,
                  }}
                >
                  {" "}
                  {i18n.t("AUTH.RETURN_TO_LOGIN")}{" "}
                </NavLink>
              </div>
            </div>
            <PoweredBy />
          </div>
        </div>
        <LadiIframe />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    forgottenPassword: (email) =>
      dispatch(authActions.forgottenPassword(email)),
  };
};

const mapStateToProps = (state) => ({ ...state.auth });

export default connect(mapStateToProps, mapDispatchToProps)(ForgottenPassword);
