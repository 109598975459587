import React from 'react';
 
import { Switch, Route, Redirect } from 'react-router-dom';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CompleteRegister from '../pages/auth/CompleteRegister';

class CompleteRegLayout extends React.Component {

    render() {
 
        return (
            <Switch>
                <Route path="/complete-reg" component={CompleteRegister} />
            </Switch>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        
    }
}

const mapStateToProps = (state) => ({ });


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompleteRegLayout));