import config from './config';
import i18n from '../i18n';

export const status = {
    OK: 200,
    CREATED: 201,
    BAD_REQUEST: 400,
    FORBIDDEN: 403,
    UNAUTHORIZED: 401,
    METHOD_NOT_ALLOWED: 405,
    REQUEST_TIMEOUT: 408,
    MANY_REQUEST: 429,
    EXPIRED_SESSION: 503,
    ERROR: 500,
}

export const endpoint = {
    //--------------------LUID------------------------
    AUTH_SIGNIN: `${config.API_V2_0}/auth/signin`,
    AUTH_SIGNUP: `${config.API_V2_0}/auth/signup`,




    // Sau nay se chuyen ham nay thanh user-info
    AUTH_USER_INFO: `${config.API_V2_0}/auth/userinfo`,
    UPDATE_USER_INFO: `${config.API_V2_0}/user/update`,
    CHANGE_PASSWORD: `${config.API_V2_0}/user/change-password`,
    FORGOTTEN_PASSWORD: `${config.API_V2_0}/user/forgotten-pass`,
    VERIFY_EMAIL: `${config.API_V2_0}/user/send-verify-mail`,
    SUBMIT_VERIFY_EMAIL: `${config.API_V2_0}/user/verify-email`,
    RESET_PASSWORD: `${config.API_V2_0}/user/reset-password`,
    GET_REF_AFFILATE: `${config.API_V2_0}/user/my-ref-affilate`,
    GET_REFFERRAL_INFO: `${config.API_V2_0}/user/my-refferral-info`,
    CHANGE_AFFILATE_CODE: `${config.API_V2_0}/user/change-affilate-code`,
    CHANGE_FLOW_AFFILATE_CODE: `${config.API_V2_0}/user/change-flow-affilate-code`,
    GET_URL_FACEBOOK: `${config.API_V2_0}/auth/facebook-generate-url`,
    GET_URL_GOOGLE: `${config.API_V2_0}/auth/google-generate-url`,
    OAUTH2_FACEBOOK: `${config.API_V2_0}/auth/oauth2-facebook`,
    OAUTH2_GOOGLE: `${config.API_V2_0}/auth/oauth2-google`,

    LOGOUT_ALL: `${config.API_V2_0}/auth/logout-all`,
    COMPLETE_REGISTER: `${config.API_V2_0}/auth/complete-register`,
    //--------------------USER-------------------------
    USER_LIST: `${config.API_V2_0}/user/list`,
    USER_INFO: `${config.API_V2_0}/user/show`,
    ADMIN_UPDATE_USER: `${config.API_V2_0}/user/update-info`,
    //--------------------LS-------------------------
    MY_ORDER: `${config.API_V2_0}/order/my-order`,
    CANCEL_ORDER: `${config.API_V2_0}/order/cancel`,
    ORDER_DETAIL: `${config.API_V2_0}/order/detail`,
    GET_TRANSACTION_DETAIL: `${config.API_V2_0}/order/get-transaction`,
    CHECKOUT_ORDER: `${config.API_V2_0}/order/checkout`,

    //--------------------ORDER-------------------------
    LIST_PAYMENT: `${config.API_V2_0}/payment/list`,
    MY_PAYMENT: `${config.API_V2_0}/payment/my-payment`,
    //--------------------REFERRAL-------------------------
    MY_REFERRALS: `${config.API_V2_0}/referral/my-referral`,
    //--------------------AFILLATE-------------------------
    MY_AFFILATE_LIST: `${config.API_V2_0}/affilate/my-affilate-list`,
    UPDATE_PAYMENT_INFO: `${config.API_V2_0}/affilate/update-my-info`,
    MY_AFFILATE_INFO: `${config.API_V2_0}/affilate/my-affilate-info`,
    ACTIVE_AFFILATE: `${config.API_V2_0}/affilate/create`,
    UPDATE_AFFILATE: `${config.API_V2_0}/affilate/update`,
    //--------------------PARTNER-------------------------
    MY_CREDIT_LOGS: `${config.API_V2_0}/partner/my-credit-log`,
    MY_CREDIT: `${config.API_V2_0}/partner/my-credit`,
    LIST_ORDER_DETAIL: `${config.API_V2_0}/partner/list-order-detail`,
    LIST_STORE: `${config.API_V2_0}/partner/list-store`,
    LIST_PARTNER_PACKAGE: `${config.API_V2_0}/partner/list-package`,
    PARTNER_UPGRADE_STORE: `${config.API_V2_0}/partner/upgrade-store`,
    ACTIVE_STORE: `${config.API_V2_0}/partner/active-store`,
    //--------------------COMMISSION-------------------------
    COMMISSION_LIST: `${config.API_V2_0}/affilate/commission-list`,
    COMMISSION_DETAIL: `${config.API_V2_0}/affilate/commission-detail`,
    MY_COMMISSION: `${config.API_V2_0}/affilate/my-commission`,
    //--------------------INVOICE-------------------------
    MY_INVOICE: `${config.API_V2_0}/invoice/my-invoice`,
    INVOICE_LIST: `${config.API_V2_0}/invoice/list-invoice`,
    INVOICE_DETAIL: `${config.API_V2_0}/invoice/detail`,
    //--------------------PACKAGE-------------------------
    // LIST_PACKAGE:`${config.API_V2_0}/package/list`,
    //--------------------POINT-------------------------
    MY_POINT_LOGS: `${config.API_V2_0}/point/my-point-log`,

    TEST: `${config.API_V2_0}/test`,
}

export default {
    LANGUAGES: [
        {
            icon: 'https://w.ladicdn.com/ladiui/icons/icon-flag-vi.svg',
            code: 'vi',
            name: 'Vietnamese',
        },
        {
            icon: 'https://w.ladicdn.com/ladiui/icons/icon-flag-en.svg',
            code: 'en',
            name: 'English',
        },
        {
            icon: 'https://w.ladicdn.com/ladiui/icons/icon-flag-th.svg',
            code: 'th',
            name: 'Thailand',
        },
        {
            icon: 'https://w.ladicdn.com/ladiui/icons/icon-flag-id.svg',
            code: 'id',
            name: 'Indonesia',
        },
        {
            icon: 'https://w.ladicdn.com/ladiui/icons/icon-flag-my.svg',
            code: 'my',
            name: 'Malaysia',
        }
    ],
    COOKIE: {
        LANGUAGE: 'LANGUAGE'
    },
    DEFAULT_CALLBACK_URL: 'https://builder.ladipage.com/ladipage',
    LADI_UID_TOKEN: 'SSID',
    USER_INFO: 'LADI_USER_INFO',
    AFFILATE_CODE: 'AFFILATE_CODE',
    LADIFLOW_REF: 'LADIFLOW_REF',
    REFERRAL_ID: 'REFERRAL_ID',
    LADI_CLIENT_ID: 'LADI_CLIENT_ID',
    BUIDER_URL: 'https://builder.ladipage.com',
    ACCOUNTs_URL: 'https://accounts.ladiuid.com',
    LOGO: "https://static.ladipage.net/57b167caca57d39c18a1c57e/ladipage-logo-white-1558579165.svg",
    DEFAULT_FORMAT_DATE: 'DD/MM/YYYY HH:mm:ss',
    DEFAULT_AVATAR: 'https://static.ladipage.net/57b167caca57d39c18a1c57e/ladipage-logo-color-1558579165.svg',
    COOKIE_EXPRIRE: 7,
    COOKIE_AFFILATE_EXPRIRE: 120,
    PAGINATION: {
        DEFAULT_LIMIT: 10,
        LIMIT_OPTIONS: [
            { value: 2, name: 2 },
            { value: 20, name: 20 },
            { value: 50, name: 50 },
            { value: 100, name: 100 },
            { value: 200, name: 200 },
        ]
    },
    PAYMENT_STATUS_OPTIONS: {
        ALL: { value: '', name: 'PAYMENT_STATUS_OPTIONS.ALL' },
        PENDING: { value: 'PENDING', name: 'PAYMENT_STATUS_OPTIONS.PENDING' },
        PAID: { value: 'PAID', name: 'PAYMENT_STATUS_OPTIONS.PAID' },
        PAID_DEBIT: { value: 'PAID_DEBIT', name: 'PAYMENT_STATUS_OPTIONS.PAID_DEBIT' },
        CANCEL: { value: 'CANCEL', name: 'PAYMENT_STATUS_OPTIONS.CANCEL' },
        INVALID: { value: 'INVALID', name: 'PAYMENT_STATUS_OPTIONS.INVALID' },
    },
    PAYMENT_METHOD_GATEWAY: {
        ZALOPAY: 'ZALOPAY',
        VNPAY: 'VNPAY',
        CASH: 'CASH'
    },
    PACKAGE_OPTIONS: {
        ALL: { value: '', name: 'All' },
        LP: { value: 'lp', name: 'Ladi Page' },
        LUID: { value: 'luid', name: 'Ladi UID' },
        LS: { value: 'ls', name: 'Ladi Sale' },
        LA: { value: 'la', name: 'Ladi Analytics' },

    },
    DEFAULT_FORMAT_DATE_TIME: 'DD/MM/YYYY HH:mm',
    DEFAULT_DATE: 'DD/MM/YYYY',
    // DEFAULT_FORMAT_DATE: 'dd/MM/yyyy',
    REPORT_OVERTIME_FORMAT_DATE: 'dd/MM',
    DEFAULT_DATE_PICKER_FORMAT_DATE: 'dd-MM-yyyy',
    DEFAULT_TIME: 'HH:mm',
    DB_DATE_TIME_FORMAT: 'dd-MM-yyyy HH:mm:ss',
    DEFAULT_DATE_YEAR: 'YYYY/MM/DD',
    PACKAGES: {
        FREE: 'FREE',
        PRO: 'PRO',
        ENTERPRISE: 'ENTERPRISE'
    },
    PAYMENT_METHOD_OPTIONS: {
        ALL: { value: '', name: 'PAYMENT_METHOD_OPTIONS.ALL' },
        ONLINE_METHOD: { value: 'ONLINE_METHOD', name: 'PAYMENT_METHOD_OPTIONS.ONLINE_METHOD' },
        OFFLINE_METHOD: { value: 'OFFLINE_METHOD', name: 'PAYMENT_METHOD_OPTIONS.OFFLINE_METHOD' }
    },
    FORM_MODE: {
        CREATE: 'CREATE',
        EDIT: 'EDIT',
        VIEW: 'VIEW'
    },
    SORT_BY: {
        ASCENDING: 1,
        DESCENDING: -1
    },
    APP: {
        LP: 'LadiPage',
        LS: 'LadiSales',
        LUID: 'Ladi UID',
        LA: 'Ladi Analytics',
        LADS: 'Ladi Advertisement',
        LC: 'Ladi Chat'
    },
    AFFILATE_LEVEL: {
        ALL: { value: '', name: '' },
        LV_1: { value: 1, name: 'Lv 1' },
        LV_2: { value: 2, name: 'Lv 2' },
        LV_3: { value: 3, name: 'Lv 3' },
        LV_4: { value: 4, name: 'Lv 4' },
    },
    REFERRAL_AMOUNT: {
        ALL: { value: '', name: 'All' },
        // LV_1: { value: 1, name: 'Lv 1' },
        // LV_2: { value: 2, name: 'Lv 2' },
        // LV_3: { value: 3, name: 'Lv 3' },
        // LV_4: { value: 4, name: 'Lv 4' },
    },
    COMMISSION_TYPE: {
        ALL: { value: '', name: 'COMMISSION_TYPE.ALL' },
        INVOICED: { value: 'INVOICED', name: 'COMMISSION_TYPE.INVOICED' },
        NOT_INVOICED: { value: 'NOT_INVOICED', name: 'COMMISSION_TYPE.NOT_INVOICED' }
    },
    USER_STATUS: {
        ACTIVE: { value: true, name: 'Active' },
        DEAD_ACTIVE: { value: false, name: 'Dead active' }
    },
    INVOICE_STATUS: {
        ALL: { value: '', name: 'INVOICE_STATUS.ALL' },
        PENDING: { value: 'PENDING', name: 'INVOICE_STATUS.PENDING' },
        PAID: { value: 'PAID', name: 'INVOICE_STATUS.PAID' },
        CANCEL: { value: 'CANCEL', name: 'INVOICE_STATUS.CANCEL' },
    },
    LANGUAGE: {
        vi: { value: 'vi', name: 'Viet Nam' },
        en: { value: 'en', name: 'English' },
    },

    TOPIC_ARN: 'arn:aws:sns:us-east-2:283758802420:LadipageTest',
    ALLOW_RESISTER: true,

    KEY_CAPCHA: "6LcpnAEVAAAAACgBWq2ZN831dLOp22-A-KPCWYuE", // V2 Product

    // KEY_CAPCHA: "6LdWvsgUAAAAALfWSAtVel8V2mlx_nQGeVMZhNt2", // V3
    // KEY_CAPCHA: '6LdbV8kUAAAAACAiFQ0IZUqAFnkObzroxgesu4L4' // V2 TEST
    ACCESS_TRADE: {
        UTM_SOURCE: '_aff_network',
        AFF_SID: '_aff_sid'
    },
    CREDIT_LOG_TYPE: {
        ALL: { value: '', name: 'CREDIT_LOG_TYPE.ALL' },
        ADMIN_ADD: { value: 'ADMIN_ADD', name: 'CREDIT_LOG_TYPE.ADMIN_ADD' },
        USER_BUY_ORDER: { value: 'USER_BUY_ORDER', name: 'CREDIT_LOG_TYPE.USER_BUY_ORDER' },
        APP_DEPOSIT: { value: 'APP_DEPOSIT', name: 'CREDIT_LOG_TYPE.APP_DEPOSIT' },
        APP_PURCHASED: { value: 'APP_PURCHASED', name: 'CREDIT_LOG_TYPE.APP_PURCHASED' },
        APP_RENEW_CREDIT: { value: 'APP_RENEW_CREDIT', name: 'CREDIT_LOG_TYPE.APP_RENEW_CREDIT' },
        MONEY_ADD: { value: 'MONEY_ADD', name: 'CREDIT_LOG_TYPE.MONEY_ADD' },
        MONEY_PURCHASED: { value: 'MONEY_PURCHASED', name: 'CREDIT_LOG_TYPE.MONEY_PURCHASED' },
    },
    WHITE_LIST_CALLBACK: [
        "ladipage.vn",
        "ladipage.com",
        "ladishare.com",
        "ladiflow.com",
        "ladisales.com",
        "ladilink.com",
        "ladiboost.com",
        "ladi.app",
        "ipay.com.vn",
        'ladichat.com'
    ],
    STORE_CONFIG: {
        APP_CODE: {
            LP: { value: 'LP', name: 'Ladi Page' },
            LS: { value: 'LS', name: 'Ladi Sales' },
            LC: { value: 'LC', name: 'Ladi Chat' },
        },
    }

}