import * as types from './types';
import * as commonTypes from '../common/types';

export default (state = {}, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            return {
                ...state,
                loading: true,
                waitting: action.waitting,
                action: commonTypes.ASYNC_START,
            }
        }

        case commonTypes.ASYNC_END: {
            return {
                ...state,
                action: commonTypes.ASYNC_END,
                waitting: commonTypes.ASYNC_END,
                loading: false,
            }
        }
        case types.LOGOUT_ALL: {
            return {
                ...state,
                action: types.LOGOUT_ALL,
                status: action.status,
                data: action.payload || false,
                message: action.message,
            }
        }
        case types.SIGNUP: {
            return {
                ...state,
                action: types.SIGNUP,
                status: action.status,
                data: action.payload,
                message: action.message,
            }
        }
        case types.SIGNIN: {
            return {
                ...state,
                action: types.SIGNIN,
                status: action.status,
                data: action.payload,
                message: action.message,
            }
        }
        case types.GET_USER_INFO: {
            return {
                ...state,
                action: types.GET_USER_INFO,
                userInfo: (action.status && action.payload) ? action.payload : {},
                event_track: action.event_track,
                status: action.status,
                verifed: action.status,
            }
        }
        case types.GET_REF_AFFILATE: {
            return {
                ...state,
                action: types.GET_REF_AFFILATE,
                affilateInfo: (action.status && action.payload) ? action.payload : { status: false },
                status: action.status,
                message: action.message,
            }
        }
        case types.GET_REFFERRAL_INFO: {
            return {
                ...state,
                action: types.GET_REFFERRAL_INFO,
                refferralInfo: (action.status && action.payload) ? action.payload : { status: false },
                status: action.status,
                message: action.message,
            }
        }
        case types.UPDATE_USER_INFO: {
            return {
                ...state,
                action: types.UPDATE_USER_INFO,
                userInfo: (action.status && action.payload) ? action.payload : { status: false },
                status: action.status,
                message: action.message,
            }
        }
        case types.CHANGE_PASSWORD: {
            return {
                ...state,
                action: types.CHANGE_PASSWORD,
                status: action.status,
                message: action.message,
            }
        }
        case types.FORGOTTEN_PASSWORD: {
            return {
                ...state,
                action: types.FORGOTTEN_PASSWORD,
                status: action.status,
                message: action.message,
            }
        }
        case types.VERIFY_EMAIL: {
            return {
                ...state,
                action: types.VERIFY_EMAIL,
                status: action.status,
                message: action.message,
            }
        }
        case types.SUBMIT_VERIFY_EMAIL: {
            return {
                ...state,
                action: types.SUBMIT_VERIFY_EMAIL,
                userInfo: (action.status && action.payload) ? action.payload : { status: false },
                status: action.status,
                message: action.message,
            }
        }
        case types.RESET_PASSWORD: {
            return {
                ...state,
                action: types.RESET_PASSWORD,
                status: action.status,
                url: action.status ? action.payload.url : '',
                message: action.message,
            }
        }
        case types.GET_URL_FACEBOOK: {
            return {
                ...state,
                action: types.GET_URL_FACEBOOK,
                status: action.status,
                url: action.payload.url,
                message: action.message,
            }
        }
        case types.GET_URL_GOOGLE: {
            return {
                ...state,
                action: types.GET_URL_GOOGLE,
                status: action.status,
                url: action.payload.url,
                message: action.message,
            }
        }
        case types.OAUTH2_FACEBOOK: {
            return {
                ...state,
                action: types.OAUTH2_FACEBOOK,
                data: (action.status && action.payload) ? action.payload.data : false,
                status: action.status,
                message: action.message,
            }
        }
        case types.OAUTH2_GOOGLE: {
            return {
                ...state,
                action: types.OAUTH2_GOOGLE,
                data: (action.status && action.payload) ? action.payload.data : false,
                status: action.status,
                message: action.message,
            }
        }
        case types.COMPLETE_REGISTER: {
            return {
                ...state,
                action: types.COMPLETE_REGISTER,
                status: action.status,
                message: action.message,
            }
        }
        case types.CHANGE_AFFILATE_CODE: {
            return {
                ...state,
                action: types.CHANGE_AFFILATE_CODE,
                status: action.status,
                message: action.message,
            }
        }
        case types.CHANGE_FLOW_AFFILATE_CODE: {
            return {
                ...state,
                action: types.CHANGE_FLOW_AFFILATE_CODE,
                status: action.status,
                message: action.message,
            }
        }
        default:
            return state;
    }
}

