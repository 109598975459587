import React from 'react';

import SigninV2 from '../pages/auth/SigninV2';
import SignupV2 from '../pages/auth/SignupV2';

import queryString from "query-string";
import ForgottenPass from '../pages/auth/ForgottenPassword';
import ResetPass from '../pages/auth/ResetPassword';
import FacebookCallback from '../pages/auth/FacebookCallback';
import GoogleCallback from '../pages/auth/GoogleCallback';
import CookiePage from '../pages/auth/CookiePage';
import { Switch, Route } from 'react-router-dom';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CompleteRegister from '../pages/auth/CompleteRegister';
import VerifyEmail from '../pages/profile/VerifyEmail';
import appConst from "../config/const";
import i18n from '../i18n';
import baseHelper from "../helpers/BaseHelper";

class AuthLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: baseHelper.getCookie(appConst.COOKIE.LANGUAGE) || 'vi',
            isIframe: false
        };
    }

    componentDidMount() {
        const lang = baseHelper.getCookie(appConst.COOKIE.LANGUAGE) || 'vi';
        i18n.changeLanguage(lang);
        const parsed = queryString.parse(this.props.location.search);
        if (parsed.iframe) {
            this.setState({
                isIframe: true
            });
        }

    }

    render() {
        let _class = 'ladiui-page-auth ' + this.state.lang;
        if (this.state.lang !== 'vi')
            _class += ' no-iframe';
        // console.log('this.state.isIframe ', this.state.isIframe)
        return (
            <div className={_class}>
                {!this.state.isIframe && <div className="ladi-popupx-inline" data-ladipage-id="65265997226e580012ecf4fc" data-align="center" data-scrolling="no"></div>}
                <Switch>
                    <Route path="/signin" component={SigninV2} />
                    <Route path="/signup" component={SignupV2} />
                    <Route path="/forgottenpass" component={ForgottenPass} />
                    <Route path="/resetpass" component={ResetPass} />
                    <Route path="/oauth2-facebook" component={FacebookCallback} />
                    <Route path="/oauth2-google" component={GoogleCallback} />
                    <Route path="/cookie" component={CookiePage} />
                    <Route path="/verify-email" component={VerifyEmail} />
                    <Route path="/complete-reg" component={CompleteRegister} />
                </Switch>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
}

const mapStateToProps = (state) => ({});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthLayout));