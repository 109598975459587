import React from "react";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import authActions from "../../redux/futures/auth/actions";
import * as types from "../../redux/futures/auth/types";
import _ from "lodash";
import i18n from "../../i18n";
import Input from "../../components/Input";
import queryString from "query-string";
import appConst from "../../config/const";
import baseHelper from "../../helpers/BaseHelper";
import LadiIframe from "./LadiIframe";
import PoweredBy from "../../components/PoweredBy";
import LadiAppLogo from "../../components/LadiAppLogo";
import ReCAPTCHA from "react-google-recaptcha";

import RegisterWarning from "./RegisterWarning";

class SignupV2 extends React.Component {
  constructor(props) {
    super(props);
    const parsed = queryString.parse(this.props.location.search);
    let ladiClientID = parsed.LADI_CLIENT_ID;
    let referralID = baseHelper.getCookie(appConst.REFERRAL_ID);
    let affilateCode = baseHelper.getCookie(appConst.AFFILATE_CODE);
    let ladiflowRef = baseHelper.getCookie(appConst.LADIFLOW_REF);
    let accessTradeUtm = baseHelper.getCookie(appConst.ACCESS_TRADE.UTM_SOURCE);
    let accessTradeSid = baseHelper.getCookie(appConst.ACCESS_TRADE.AFF_SID);
    // console.log(parsed);
    if (referralID != undefined && referralID != "") {
      parsed.referral_id = referralID;
    }
    if (affilateCode != undefined && affilateCode != "") {
      parsed.affilate_code = affilateCode;
    }
    if (ladiflowRef != undefined && ladiflowRef != "") {
      parsed.ladiflow_ref = ladiflowRef;
    }
    if (accessTradeUtm != undefined && accessTradeUtm != "") {
      parsed.utm_source = accessTradeUtm;
    }

    if (accessTradeSid != undefined && accessTradeSid != "") {
      parsed.aff_sid = accessTradeSid;
    }

    if (ladiClientID != undefined && ladiClientID != "") {
      baseHelper.setCookie(appConst.LADI_CLIENT_ID, ladiClientID);
    }

    this.state = {
      email: "",
      password: "",
      errorPassword: "",
      // confirmpass: '',
      app_code: "",
      phone: "",
      name: "",
      recaptchaToken: "",
      recaptchaLoaded: false,
      refObject: parsed,
      loading: false,
      typeInput: "password",
      iconClass: "icon-preview",
      classNormal: "ladiui btn btn-primary full-width", //"ladiui button primary",
      classLoading: "ladiui loader btn btn-primary full-width",
      classDisable: "ladiui btn btn-disable full-width",
      classApply: "ladiui btn btn-primary full-width",
      search: this.props.location.search,
      token: "",
      lang: baseHelper.getCookie(appConst.COOKIE.LANGUAGE) || 'vi',
      iPayLogo: null,

      layout: 1
    };
    this.isIframe = false;
    this.isConfirm = false;
    this.inputsRef = new Set();
    this.recaptchaRef = React.createRef();
    this.ref = React.createRef();
  }
  componentDidUpdate() {


  }
  async componentDidMount() {

    window.LadiUI.init();
    document.addEventListener("mouseleave", this.handlerBack);

    // var that = this;
    // window.addEventListener("beforeunload", function () {
    //   alert('abd');
    //   that.handlerBack();
    //   if (!that.isConfirm) {
    //     that.isConfirm = true;
    //     return;
    //   }
    // });
    const parsed = queryString.parse(this.props.location.search);
    if (parsed.callback_url && parsed.callback_url.indexOf('app.ipay.com.vn') >= 0) {
      this.setState({
        iPayLogo: 'https://w.ladicdn.com/ladisales/ipay.svg'
      });
    }
    if (parsed.iframe) {
      this.isIframe = true;
    }
    if (parsed.layout) {
      this.setState({ layout: parsed.layout });
    }
    document.body.classList.add('iframe-popupx');
    document.title = "Đăng ký LadiApp I All-in-One application for businesses";

  }


  componentWillUnmount() {
    document.removeEventListener("mouseleave", this.handlerBack);
  }
  componentWillMount() {
    this.redirectAuth(this.props);

  }

  handlerBack = () => {
    if (this.isConfirm || this.isIframe) {
      return;

    }
    window.LadiUI.showModal('modal-register-warning');
    this.isConfirm = true;
    return false;
  }
  redirectAuth(props) {
    const token = baseHelper.getCookie(appConst.LADI_UID_TOKEN);

    if (token) {
      this.props.getUserinfo({
        token,
        search: this.props.location.search,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.action == types.SIGNUP) {
      const that = this;
      this.setState({
        loading: false,
        classApply: that.state.classNormal,
      });
      if (nextProps.status) {
        localStorage.setItem("email", this.state.email);
        let parsed = {};
        if (this.state.search) {
          parsed = queryString.parse(this.props.location.search);
        }
        if (!parsed.callback_url) {
          this.props.history.push("/account/profile");
        }
        if (nextProps.event_track && window.gtag) {
          window.gtag('event', nextProps.event_track, {
            'event_category': 'LadiUID',
            'event_label': 'Section'
          });
        }
        this.setState({ token: nextProps.data.token });
      } else {
        window.LadiUI.showErrorMessage('', nextProps.message, 'OK');
        this.refreshCaptcha();
      }
    }
    if (
      nextProps.action === types.GET_URL_FACEBOOK ||
      nextProps.action === types.GET_URL_GOOGLE
    ) {
      if (nextProps.status) {
        if (this.isIframe) {
          // window.parent.postMessage(JSON.stringify({
          //   ladi_uid: {
          //     redirect_url: nextProps.url
          //   }
          // }), '*');
          window.parent.location.href = nextProps.url;
          // console.log('nextProps.url = ', nextProps.url)
        } else {
          window.location.href = nextProps.url;
        }
      } else {
        window.LadiUI.showErrorMessage(i18n.t('BASE.NOTIFICATION'), nextProps.message, 'OK');
      }
    }
  }

  onChangeInput = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  signUp = async (event) => {
    event.preventDefault();
    if (this.isIframe && this.state.layout == 3 && window.gtag) {
      window.gtag('event', 'home_btn_signup_click', {
        'event_category': 'LadiUID',
        'event_label': 'Section'
      });
    }
    // Validate
    let errors = [];
    const inputsRef = _.compact(Array.from(this.inputsRef));
    _.map(inputsRef, (ref) => {
      if (ref.validate) {
        errors = errors.concat(ref.validate());
      }
    });

    _.compact(errors);
    if (errors.length > 0) {
      const errorMessage = errors.join("<br/>");
      window.LadiUI.showErrorMessage(i18n.t('BASE.NOTIFICATION'), errorMessage, 'OK');
      return;
    }

    // this.setState({
    //     recaptchaToken: token
    // })

    const {
      email,
      password,
      confirmpass,
      name,
      phone,
      refObject,
      recaptchaToken,

    } = this.state;

    // if (password != confirmpass) {
    //     window.LadiUI.toast('danger', '', 'Mật khẩu và xác nhận mật khẩu không khớp');
    //     return;
    // }
    const parsed = queryString.parse(this.props.location.search);
    let referralID = baseHelper.getCookie(appConst.REFERRAL_ID);
    let affilateCode = baseHelper.getCookie(appConst.AFFILATE_CODE);
    let ladiflowRef = baseHelper.getCookie(appConst.LADIFLOW_REF);
    let accessTradeUtm = baseHelper.getCookie(appConst.ACCESS_TRADE.UTM_SOURCE);
    let accessTradeSid = baseHelper.getCookie(appConst.ACCESS_TRADE.AFF_SID);
    const data = {
      email: email,
      password: password,
      phone: phone,
      app_code: "luid",
      referral_id: referralID,
      affilate_code: affilateCode,
      ladiflow_ref: ladiflowRef,
      fullname: name,
    };
    if (parsed.app_code) {
      data.app_code = parsed.app_code;
    }
    if (parsed.callback_url) {
      const parseCallback = queryString.parseUrl(
        decodeURIComponent(parsed.callback_url)
      );
      _.map(parseCallback.query, (value, key) => {
        data[key] = value;
      });
    }
    if (parsed.utm_source) {
      data.utm_source = parsed.utm_source;
    }

    if (accessTradeUtm && accessTradeUtm != "") {
      data.utm_source = accessTradeUtm;
    }
    if (accessTradeSid && accessTradeSid != "") {
      data.aff_sid = accessTradeSid;
    }

    if (
      accessTradeUtm &&
      accessTradeSid &&
      accessTradeUtm == "accesstrade" &&
      accessTradeSid != ""
    ) {
      delete data.affilate_code;
    }

    data.search = this.state.search;
    const that = this;
    if (!baseHelper.passwordValidate(password.trim())) {
      this.setState({ errorPassword: i18n.t('AUTH.PASSWORD_INVALID') })
      return;
    } else {
      this.setState(
        {
          errorPassword: '',
          loading: true,
          classApply: that.state.classLoading,
        },
        async () => {
          if (!recaptchaToken || recaptchaToken == "") {
            that.setState({
              loading: false,
              classApply: that.state.classNormal,
            });
            // window.LadiUI.toast('danger', '', 'Please verify you are human');
            return;
          }
          data.token = recaptchaToken;
          if (!this.isIframe && this.state.layout == 3) {
            data.event_track = 'home_btn_signup_success';
          }
          that.props.signUp(data);
        }
      );
    }
  };

  callClose = (modal) => {
    modal.stopPropagation();
    if (modal.target.id && (modal.target.id == 'layout-2' || modal.target.id == 'close-login-frame')) {
      if (window.parent) {
        window.parent.postMessage(JSON.stringify({
          ladi_uid: {
            is_close_iframe: true
          }
        }), '*');
      }
    }
  }

  geturlFaceBook = () => {
    const { refObject } = this.state;
    delete refObject.logout;
    if (window.gtag) {
      if (this.isIframe && this.state.layout == 3) {
        window.gtag('event', 'home_FB_btn_signup_click', {
          'event_category': 'LadiUID',
          'event_label': 'Section'
        });
      } else {
        window.gtag('event', 'Facebook_btn_login_click', {
          'event_category': 'LadiUID',
          'event_label': 'Test'
        });
      }
    }
    if (this.isIframe && !refObject.callback_url) {
      refObject.app_code = 'lp';
      refObject.callback_url = appConst.DEFAULT_CALLBACK_URL;
    }
    if (this.isIframe && this.state.layout == 3) {
      refObject.event_track = 'home_FB_btn_signup_success';
    }
    // console.log('this.state.refObject = ', refObject);

    delete refObject.iframe;
    delete refObject.layout;
    // return;
    this.props.getUrlFacebook(refObject);
  };

  trackRedirectSignin = () => {
    if (!this.isIframe && this.state.layout == 3) {
      window.gtag('event', 'home_btn_relogin_click', {
        'event_category': 'LadiUID',
        'event_label': 'Section'
      });
    }
  }

  geturlGoogle = () => {
    const { refObject } = this.state;
    delete refObject.logout;
    if (window.gtag) {
      if (this.isIframe && this.state.layout == 3) {
        window.gtag('event', 'home_GG_btn_signup_click', {
          'event_category': 'LadiUID',
          'event_label': 'Section'
        });
      } else {
        window.gtag('event', 'Google_btn_login_click', {
          'event_category': 'LadiUID',
          'event_label': 'Test'
        });
      }
    }
    if (this.isIframe && !refObject.callback_url) {
      refObject.app_code = 'lp';
      refObject.callback_url = appConst.DEFAULT_CALLBACK_URL;
    }
    if (this.isIframe && this.state.layout == 3) {
      this.state.refObject.event_track = 'home_GG_btn_signup_success';
    }
    delete refObject.iframe;
    delete refObject.layout;
    // return;
    // console.log('this.state.refObject = ', this.state.refObject)
    // return;
    this.props.getUrlGoogle(this.state.refObject);
  };

  showPassword = () => {
    const { typeInput } = this.state;
    if (typeInput == "password") {
      this.setState({
        typeInput: "text",
        iconClass: "icon-b-preview",
      });
    }
    if (typeInput == "text") {
      this.setState({
        typeInput: "password",
        iconClass: "icon-preview",
      });
    }
  };

  captchaSuccess = (token) => {
    const that = this;
    return new Promise(function (resolve, reject) {
      that.setState(
        {
          recaptchaToken: token,
        },
        () => {
          resolve();
        }
      );
      //Your code logic goes here

      //Instead of using 'return false', use reject()
      //Instead of using 'return' / 'return true', use resolve()
    }); //end promise

    // return;
    // this.recaptchaDone(token);
  };

  refreshCaptcha = () => {
    this.recaptchaRef.reset();
  };

  recaptchaLoaded(token) {
    console.log("recaptchaLoaded:", token);
  }

  onChangeCaptcha = (value) => {
    this.setState({
      recaptchaToken: value,
    });
  };

  render() {
    const {
      email,
      password,
      errorPassword,
      token,
      name,
      phone,
      typeInput,
      iconClass,
      recaptchaToken,
      classNormal,
      classDisable,
      isIframe,
      layout
    } = this.state;
    const search = this.props.location.search;
    const affilateCode = baseHelper.getCookie(appConst.AFFILATE_CODE);
    const ladiflowRef = baseHelper.getCookie(appConst.LADIFLOW_REF);
    const checkAff =
      affilateCode != undefined &&
      affilateCode != "" &&
      affilateCode != "false";
    const checkLFAff =
      ladiflowRef != undefined &&
      ladiflowRef != "" &&
      ladiflowRef != "false";

    return (
      appConst.ALLOW_RESISTER && (
        <div id={'layout-' + layout} className={"ladiui auth " + (token != '' ? "redirect-delay" : '') + (' layout-' + layout)} onClick={this.callClose} >
          {token == '' &&
            <div className="ladiui-login-wrap" style={this.state.iPayLogo ? { width: '100%' } : {}}>

              <div className="ladiui-login">
                {
                  layout == 2 &&
                  <button
                    id="close-login-frame"
                    type="button"
                    // data-dismiss="modal"
                    // data-target="modal-register-warning"
                    className="ladiui modal-close"
                    onClick={this.callClose}
                  >
                    <i className="ladiui icon icon-ldp-close"></i>
                  </button>
                }
                {!this.isIframe && <LadiAppLogo logoURL={this.state.iPayLogo} />}
                {!this.isIframe
                  && <div style={{ textAlign: "center" }}>

                    <h1 className="form-title">{i18n.t('AUTH.SIGN_UP_ACCCOUNT')}</h1>
                    <p className="form-sub">{i18n.t('AUTH.FILL_YOUR_INFO_TO_SIGNUP')}</p>
                    <p className="form-sub">{i18n.t('AUTH.FILL_YOUR_INFO_TO_SIGNUP2')}</p>
                  </div>
                }
                {this.isIframe &&
                  <div style={{ textAlign: "center" }}>

                    <h1 className="form-title">{i18n.t('AUTH.SIGN_UP_ACCCOUNT_L3')}</h1>
                  </div>
                }
                <div className="social-login">

                  <div className="social-button">
                    <a
                      href="#"
                      className="ladiui btn btn-secondary login-facebook "
                      onClick={this.geturlFaceBook}
                    >
                      <i className="ladiui icon ldicon-logo-facebook"></i>
                      {/* <img src="https://w.ladicdn.com/ladiui/icons/ldicon-logo-facebook.svg" alt="Facebook" />{" "} */}
                      <span>Tiếp tục với Facebook</span>
                    </a>
                    <a
                      href="#"
                      className="ladiui btn btn-secondary login-google "
                      onClick={this.geturlGoogle}
                    >
                      <i className="ladiui icon ldicon-logo-google"></i>
                      {/* <img src="https://w.ladicdn.com/ladiui/icons/ldicon-logo-google.svg" alt="Google" />{" "}  */}
                      <span> Tiếp tục với Google</span>
                    </a>


                  </div>
                </div>
                <span className="login-with">
                  <h1>{i18n.t('AUTH.OR')} </h1>
                </span>
                <div className="ladiui-login-form">
                  <div className={"ladiui-form create-user-form " + (layout != 3 ? "signup" : '')}>
                    <form onSubmit={this.signUp}>
                      <div className="ladiui-form-group ">
                        <div className={layout != 3 ? "col-50" : ''}>
                          <label className="ladiui title-text-form-lever2">{i18n.t('AUTH.FULLNAME')}</label>
                          <Input
                            ref={(ref) => this.inputsRef.add(ref)}
                            name="name"
                            validationName="Name"
                            placeholder={i18n.t('AUTH.FILL_YOUR_FULLNAME')}
                            value={name}
                            onChange={this.onChangeInput}
                            validations={{ isRequired: true }}
                          />
                        </div>
                        <div className={layout != 3 ? "col-50" : 'mt-20'}>
                          <label className="ladiui title-text-form-lever2">{i18n.t('AUTH.EMAIL')}</label>
                          <Input
                            ref={(ref) => this.inputsRef.add(ref)}
                            name="email"
                            validationName="Email"
                            placeholder={i18n.t('AUTH.FILL_YOUR_EMAIL')}
                            value={email}
                            onChange={this.onChangeInput}
                            validations={{ isRequired: true, email: true }}
                          />
                        </div>

                      </div>
                      <div className="ladiui-form-group ">
                        <div className={layout != 3 ? "col-50" : ''}>
                          <label className="ladiui title-text-form-lever2">{i18n.t('AUTH.PASSWORD')}</label>
                          <Input
                            ref={(ref) => this.inputsRef.add(ref)}
                            name="password"
                            validationName="Password"
                            placeholder={i18n.t('AUTH.FILL_YOUR_PASS')}
                            value={password}
                            onChange={this.onChangeInput}
                            validations={{
                              isRequired: true,
                              minLength: 8,
                              maxLength: 20,
                            }}
                            type={typeInput}
                          />
                          <i
                            className={
                              `ladi-icon ` + iconClass + ` ladi-date-picker-icon`
                            }
                            onClick={this.showPassword}
                          ></i>
                        </div>
                        <div className={layout != 3 ? "col-50" : 'mt-20'}>
                          <label className="ladiui title-text-form-lever2">{i18n.t('AUTH.PHONE')}</label>
                          <Input
                            ref={(ref) => this.inputsRef.add(ref)}
                            name="phone"
                            validationName="Phone"
                            placeholder={i18n.t('AUTH.FILL_YOUR_PHONE')}
                            value={phone}
                            onChange={this.onChangeInput}
                            validations={{ isRequired: false, phone: true }}
                          />

                        </div>

                      </div>

                      <div className="">
                        <div className="error-password">{errorPassword}</div>
                      </div>

                      <div className="ladiui-form-group recapcha">
                        <div>
                          <ReCAPTCHA
                            ref={(ref) => (this.recaptchaRef = ref)}
                            sitekey={appConst.KEY_CAPCHA}
                            onChange={this.onChangeCaptcha}
                            onExpired={this.refreshCaptcha}
                            type={"image"}
                          />
                        </div>
                      </div>
                      <div className="ladiui-form-group">
                        <button
                          ref={this.ref}
                          className={this.state.classApply}
                          onClick={this.signUp}
                          disabled={this.state.loading}
                        >
                          {i18n.t('AUTH.COMPLETE')}
                        </button>
                      </div>
                    </form>
                    {/* {layout == 3 &&
                      <form onSubmit={this.signUp}>
                        <div className="ladiui-form-group ">
                          <label className="ladiui title-text-form-lever2">{i18n.t('AUTH.FULLNAME')}</label>
                          <Input
                            ref={(ref) => this.inputsRef.add(ref)}
                            name="name"
                            validationName="Name"
                            placeholder={i18n.t('AUTH.FILL_YOUR_FULLNAME')}
                            value={name}
                            onChange={this.onChangeInput}
                            validations={{ isRequired: true }}
                          />
                        </div>
                        <div className="ladiui-form-group ">
                          <label className="ladiui title-text-form-lever2">{i18n.t('AUTH.EMAIL')}</label>
                          <Input
                            ref={(ref) => this.inputsRef.add(ref)}
                            name="email"
                            validationName="Email"
                            placeholder={i18n.t('AUTH.FILL_YOUR_EMAIL')}
                            value={email}
                            onChange={this.onChangeInput}
                            validations={{ isRequired: true, email: true }}
                          />

                        </div>
                        <div className="ladiui-form-group ">

                          <label className="ladiui title-text-form-lever2">{i18n.t('AUTH.PASSWORD')}</label>
                          <Input
                            ref={(ref) => this.inputsRef.add(ref)}
                            name="password"
                            validationName="Password"
                            placeholder={i18n.t('AUTH.FILL_YOUR_PASS')}
                            value={password}
                            onChange={this.onChangeInput}
                            validations={{
                              isRequired: true,
                              minLength: 6,
                              maxLength: 20,
                            }}
                            type={typeInput}
                          />
                          <i
                            className={
                              `ladi-icon ` + iconClass + ` ladi-date-picker-icon`
                            }
                            onClick={this.showPassword}
                          ></i>
                        </div>
                        <div className="ladiui-form-group">
                          <label className="ladiui title-text-form-lever2">{i18n.t('AUTH.PHONE')}</label>
                          <Input
                            ref={(ref) => this.inputsRef.add(ref)}
                            name="phone"
                            validationName="Phone"
                            placeholder={i18n.t('AUTH.FILL_YOUR_PHONE')}
                            value={phone}
                            onChange={this.onChangeInput}
                            validations={{ isRequired: false, phone: true }}
                          />


                        </div>

                        <div className="">
                          <div className="error-password">{errorPassword}</div>
                        </div>

                        <div className="ladiui-form-group recapcha">
                          <div>
                            <ReCAPTCHA
                              ref={(ref) => (this.recaptchaRef = ref)}
                              sitekey={appConst.KEY_CAPCHA}
                              onChange={this.onChangeCaptcha}
                              onExpired={this.refreshCaptcha}
                              type={"audio"}
                            />
                          </div>
                        </div>
                        <div className="ladiui-form-group">
                          <button
                            ref={this.ref}
                            className={this.state.classApply}
                            onClick={this.signUp}
                            disabled={this.state.loading}
                          >
                            {i18n.t('AUTH.COMPLETE')}
                          </button>
                        </div>
                      </form>
                    } */}

                  </div>

                  <br></br>
                  {<div id="policy-text" style={{ textAlign: "center", fontSize: "12px" }}>(*) {i18n.t('AUTH.SIGNUP_CONFIRM_RULE')}<a href="https://ladipage.vn/dieukhoandichvu" target="_blank">{i18n.t('AUTH.TERM_OF_USE')}</a>, <a href="https://ladipage.vn/chinhsachbaomat" target="_blank"> {i18n.t('AUTH.SERCURE_POLICY')}</a> {i18n.t('AUTH.POLICY_FROM')}.</div>}

                  <div className="question-form">
                    {i18n.t('AUTH.HAVE_ACCOUNT')}
                    {layout == 1 && <a href={`/signin${search}`}>&nbsp;{i18n.t('AUTH.RETURN_TO_LOGIN')}</a>}

                    {layout != 1 && <NavLink onClick={this.trackRedirectSignin}
                      to={{
                        pathname: "/signin",
                        search: search,
                      }}
                    >
                      &nbsp;{i18n.t('AUTH.RETURN_TO_LOGIN')}
                    </NavLink>
                    }

                    {/* {layout == 2 && <NavLink  to={`/signin${search}`}>&nbsp;{i18n.t('AUTH.RETURN_TO_LOGIN')}</NavLink>} */}
                    {/* // <a href={`/signin${search}`}>&nbsp;{i18n.t('AUTH.RETURN_TO_LOGIN')}</a>} */}
                  </div>
                  {!checkAff ? (
                    <div></div>
                  ) : (
                    <div className="question-form">
                      {i18n.t('AUTH.AFF_BY')}: {affilateCode || ladiflowRef}
                    </div>
                  )}
                  {!checkLFAff ? (
                    <div></div>
                  ) : (
                    <div className="question-form">
                      {i18n.t('AUTH.LADIFLOW_AFF_BY')}: {ladiflowRef}
                    </div>
                  )}
                </div>
                {layout == 1 &&
                  <PoweredBy />
                }
              </div>
            </div>
          }
          {(!this.state.iPayLogo && layout == 1) ? <LadiIframe /> : <></>}
          <RegisterWarning />
        </div>
      )
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signUp: (data) => dispatch(authActions.signUp(data)),
    getUrlFacebook: (refObject) =>
      dispatch(authActions.getUrlFacebook(refObject)),
    getUrlGoogle: (refObject) => dispatch(authActions.getUrlGoole(refObject)),
    getUserinfo: (token) => dispatch(authActions.getUserInfo(token)),
  };
};

const mapStateToProps = (state) => ({ ...state.auth });

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignupV2));
